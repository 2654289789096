import ClipboardJS from 'clipboard';
import Cookies from 'js-cookie';
import moment from 'moment/min/moment-with-locales';
import { RequestVM } from './RequestViewModels.js';
import { Common, JSPost } from './Common.js';
import DialogMgr from './DialogMgr.js';
import { FrequencyDataDetailed } from './AssignFrequenciesEntities.js';

var DashboardVM = function (jsonData) {
  var self = this;

  self.defaultListCount = 15;
  self.sortingRequests = ko.observable(false);

  self.cookieName = 'efcDashboardSearch';
  var searchCookie = Cookies.getJSON(self.cookieName);

  self.showConflictCookieName = 'efcDashboardShowConflict';
  var showConflictCookie = Cookies.getJSON(self.showConflictCookieName);

  self.ShowConflictsDefaultValue = jsonData.RequestSearch.ShowConflicts;
  if (searchCookie && !self.ShowConflictsDefaultValue)
    //&& searchCookie && searchCookie.ShowConflicts)
    searchCookie.ShowConflicts = false;
  else if (searchCookie && showConflictCookie) searchCookie.ShowConflicts = showConflictCookie.ShowConflicts;

  if (searchCookie) {
    searchCookie.GetNextPage = false;
    searchCookie.PageNum = 1;
  }

  self.RequestSearch = new RequestSearch(searchCookie || jsonData.RequestSearch, self.defaultListCount);
  self.RequestSearchHold = new RequestSearch(searchCookie || jsonData.RequestSearch, self.defaultListCount);

  self.Areas = ko.observableArray((jsonData.Areas || []).sort(Common.StringSortFunc('Name')));
  self.AvailVenues = ko.observableArray((jsonData.Venues || []).sort(Common.StringSortFunc('Name')));
  self.AvailEvents = ko.observableArray(
    (jsonData.Events || []).map(function (e) {
      return new EventTokenVM(e);
    })
  );
  self.Activities = ko.observableArray((jsonData.Activities || []).sort(Common.StringSortFunc('Name')));
  self.Companies = ko.observableArray((jsonData.Companies || []).sort(Common.StringSortFunc('Name')));
  self.StatusesAll = ko.observableArray(jsonData.StatusesAll || []);
  self.EquipmentTypes = ko.observableArray((jsonData.EquipmentTypes || []).sort(Common.StringSortFunc('Name')));
  self.Manufacturers = ko.observableArray((jsonData.Manufacturers || []).sort(Common.StringSortFunc('Name')));
  self.Models = ko.observableArray((jsonData.Models || []).sort(Common.StringSortFunc('Name')));
  self.AppUsers = ko.observableArray((jsonData.AppUsers || []).sort(Common.StringSortFunc('Name')));

  self.$eventsTokenField = $('');
  self.tokenChoices = [];
  self.$activitiesTokenField = $('');
  self.tokenActivityChoices = [];
  self.SelectedEventIDs = ko.observable(self.RequestSearchHold.EventIDs().join(', '));
  self.SelectedActivityIDs = ko.observable(self.RequestSearchHold.ActivityIDs().join(', '));

  self.ConflictsStale = ko.observable(true);
  self.GetConflictIndicator = ko.pureComputed(function () {
    return self.ShowConflictsDefaultValue && self.RequestSearchHold.ShowConflicts();
  });

  self.RequestList = ko.observable(null); //RequestListViewModel
  self.RequestCount = ko.pureComputed(function () {
    if (self.RequestList() == null) return 0;
    return self.RequestList().Requests().length;
  });
  self.Requests = ko.computed(function () {
    if (self.RequestList() == null) return [];
    else return self.RequestList().Requests();
  });
  self.ShowReducedSearchOptions = ko.observable(jsonData.ShowReducedSearchOptions); //Rnder by hide fields via knockout to avoid search not working.

   var dashLoaded = JSON.parse(Cookies.get("efcDashboardSearchCollapse") || "{}");   
   self.SearchCollapsed = ko.observable(!dashLoaded.loaded ? true : dashLoaded.collapsed || false);   
   self.SetSearchCollapsed = function (collapsed) {
      self.SearchCollapsed(collapsed);           
      dashLoaded.loaded = true;
      dashLoaded.collapsed = collapsed;
      Cookies.set("efcDashboardSearchCollapse", JSON.stringify(dashLoaded));
   }

  self.load = function () {
    if (self.RequestSearchHold.AreaIDs().length > 0) {
      self.reloadAvailVenues(self.RequestSearchHold.AreaIDs(), true);
    } else if (self.RequestSearchHold.VenueIDs().length > 0 || !self.RequestSearchHold.CurrentEventsOnly())
      self.reloadAvailEvents(self.RequestSearchHold.VenueIDs(), true);
    else if (self.RequestSearchHold.EventIDs().length > 0) self.reloadAvailActivities(self.RequestSearchHold.EventIDs(), true);

    self.setupSearchToggle($('#menu-toggle'));
    self.pinToggle($('#sidebarPin'));
    self.setAreaChangeTrigger($('#searchAreaID'));
    self.setVenueChangeTrigger($('#searchVenueID'));
    self.setCurrentEventsChangeTrigger($('#CurrentEventsOnly'));

    $('#EventsToFilter').val(self.SelectedEventIDs());
    self.SetupTokenField();

    setTimeout(function () {
      $('#EventsToFilter-tokenfield').blur();
    }, 0);

    $('#ActivitiesToFilter').val(self.SelectedActivityIDs());
    self.SetupActivityTokenField();

    setTimeout(function () {
      $('#ActivitiesToFilter-tokenfield').blur();
      $('#arrowContainer').on('click', () => {
        $('#menu-toggle').trigger('click');
      });
    }, 0);

    ko.applyBindings(self, document.getElementById('wrapper'));

    $('.currentEventsSlide').toggleClass('on', self.RequestSearchHold.CurrentEventsOnly());

    self.updateAllSelecListSelectedItemSort();

    self.SelectedEventIDs.subscribe(function (selectedEventIDs) {
       var selectedEvents = selectedEventIDs.split(',');
       //original height: calc(100 % - 50px)
       var searchHeightByEvents = (selectedEvents.length * 27) - 150;
       $("#dashSearch").css({ "height": "calc(100% + " + searchHeightByEvents + "px)" });
       self.reloadAvailActivities();
    });

    self.LoadList();

    var clipboard = new ClipboardJS('.btnCopyEmail');
    clipboard.on('error', function (e) {
      console.log(e);
    });
  };

  self.updateAllSelecListSelectedItemSort = function () {
    Common.ShowSelectedItemsOnTop('#searchAreaID');
    Common.ShowSelectedItemsOnTop('#searchVenueID');
    Common.ShowSelectedItemsOnTop('#searchCompanyID');
    Common.ShowSelectedItemsOnTop('#searchRequestedBy');
    Common.ShowSelectedItemsOnTop('#searchEquipmentTypeID');
    Common.ShowSelectedItemsOnTop('#searchManufacturerID');
    Common.ShowSelectedItemsOnTop('#searchModelID');
  };

  self.LoadList = function (requestID, callback, callbackParam) {
    if (self.RequestSearch.EndReqDateInValid() || self.RequestSearch.StartReqDateInValid()) return;

    $('#loadMessage').text(function () {
      if (self.RequestSearch.GetNextPage) return 'Loading more records...';
      else if (self.sortingRequests()) return 'Sorting records...';
      else return 'Updating records...';
    });
    $('#loadMessage').show();

    var getConflicts = self.GetConflictIndicator();

    var ajaxParams = {};
    ajaxParams.search = ko.toJS(self.RequestSearch);
    ajaxParams.requestID = requestID > 0 ? requestID : 0;

    $.post(RazorShared.baseUrl + 'Dashboard/GetRequestList', ajaxParams, function (data) {
      if (data.success) {
        if (self.RequestList() == null) self.RequestList(new RequestListVM(data.requestListVM));
        else {
          if (self.RequestSearch.GetNextPage) self.RequestList().AppendRequests(data.requestListVM.Requests);
          else if (data.removeAtIndex != null && data.removeAtIndex >= 0) {
            self.RequestList().RemoveRequest(data.removeAtIndex);
            getConflicts = false;
          } else if (data.updateAtIndex != null && data.updateAtIndex >= 0) {
            self.RequestList().UpdateRequest(data.requestListVM.Requests[data.updateAtIndex], data.updateAtIndex);
            if (data.requestListVM.Requests[data.updateAtIndex].ApprovedFrequenciesCount > 0)
              self.GetFrequencyConflicts([data.requestListVM.Requests[data.updateAtIndex].RequestID]);
            getConflicts = false;
          } else self.RequestList().UpdateRequests(data.requestListVM.Requests);
        }

        if (!$('ul.sidebar-nav').hasClass('loaded')) $('ul.sidebar-nav').addClass('loaded');

        var resetPaging = $('#resetPaging').val();
        if (resetPaging == 1) {
          self.RequestSearch.PageNum = 1;
          self.RequestSearchHold.PageNum = 1;
        }
        if (self.RequestSearch.GetNextPage) {
          self.RequestSearch.GetNextPage = false;
          self.RequestSearchHold.GetNextPage = false;
        }
        if (self.sortingRequests()) self.sortingRequests(false);

        if (getConflicts) {
          self.GetFrequencyConflicts(
            data.requestListVM.Requests.filter(function (r) {
              return r.ApprovedFrequenciesCount > 0;
            }).map(function (r) {
              return r.RequestID;
            })
          );
          self.ConflictsStale(false);
        }

        if (typeof callback === 'function') callback(callbackParam);
      } else {
        Common.Dialog('Dashboard', null, data.error);
      }
      self.CheckConflictsToggle();
    })
      .fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Dashboard', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
      })
      .always(function () {
        $('#loadMessage').hide();
      });

    Cookies.set(self.cookieName, ko.toJS(self.RequestSearch), { expires: 30 });
  };

  self.GetFrequencyConflicts = function (requestIDs) {
    if (requestIDs == undefined || requestIDs.length == 0) return;

    requestIDs.forEach(function (id) {
      $.post(RazorShared.baseUrl + 'Dashboard/GetDashboardFrequencyConflicts', { id: id }, function (data) {
        if (data.success && data.frequenciesWithConflicts) {
          self.UpdateConflict(id, data.frequenciesWithConflicts);
        }
      }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
        Common.Dialog('Error getting frequency conflicts', null, XMLHttpRequest.status);
      });
    });
  };

  self.UpdateConflict = function (requestID, frequenciesWithConflicts) {
    var request = self.Requests().find(function (r) {
      return r.RequestID == requestID;
    });

    if (request) {
      request.FilteredEquipments().forEach(function (equipment) {
        equipment.Frequencies().filter(function (ef) {
          var idx = frequenciesWithConflicts.indexOf(ef.RequestEquipmentFrequencyID);
          ef.HasConflict(idx >= 0);
        });
      });
      request.ConflictProcessed(true);
    }
  };

  self.ToggleShowConflicts = function () {
    self.RequestSearchHold.ShowConflicts(!self.RequestSearchHold.ShowConflicts());
    self.RequestSearch.ShowConflicts(self.RequestSearchHold.ShowConflicts());

    if (self.RequestSearch.ShowConflicts() && self.ConflictsStale()) {
      var ids = self.Requests().map(function (r) {
        return r.RequestID;
      });
      if (ids && ids.length > 0) self.GetFrequencyConflicts(ids);
    }

    self.CheckConflictsToggle();
    Cookies.set(self.showConflictCookieName, ko.toJS({ ShowConflicts: self.RequestSearch.ShowConflicts() }), { expires: 30 });
  };
  self.CheckConflictsToggle = function () {
    $('.confSlide').toggleClass('on', self.RequestSearchHold.ShowConflicts());
    $('.confSlide .toggle').html(self.RequestSearchHold.ShowConflicts() ? 'ON' : 'OFF');
  };

  self.ToggleCurrentEvents = function () {
    self.RequestSearchHold.CurrentEventsOnly(!self.RequestSearchHold.CurrentEventsOnly());
    self.RequestSearch.CurrentEventsOnly(!self.RequestSearch.CurrentEventsOnly());

    $('.currentEventsSlide').toggleClass('on', self.RequestSearchHold.CurrentEventsOnly());
    $('.currentEventsSlide .toggle').html(self.RequestSearchHold.CurrentEventsOnly() ? 'ON' : 'OFF');

    self.ApplyFilters();
  };

  self.ApplyFilters = function () {
    self.RequestSearchHold.EventIDs(self.SelectedEventIDs().length == 0 ? [] : self.SelectedEventIDs().split(', '));
    self.RequestSearchHold.RetrieveCount = self.defaultListCount; //reset list count for new search
    self.RequestSearchHold.ActivityIDs(self.SelectedActivityIDs().length == 0 ? [] : self.SelectedActivityIDs().split(', '));
    self.RequestSearchHold.RetrieveCount = self.defaultListCount; //reset list count for new search
    self.RequestSearchHold.PageNum = 1; //reset list count for new search
    self.RequestSearchHold.GetNextPage = false;
    self.CopySearchFilters(self.defaultListCount);
    self.RequestList().Requests([]);
    self.ConflictsStale(true);
    self.LoadList();
  };

  self.CopySearchFilters = function (listCount) {
    var temp = self.RequestSearch;
    // self.RequestSearch = new RequestSearch(ko.toJS(self.RequestSearchHold), listCount);

    self.RequestSearch.RequestID(self.RequestSearchHold.RequestID());
    self.RequestSearch.AreaIDs(self.RequestSearchHold.AreaIDs());
    self.RequestSearch.VenueIDs(self.RequestSearchHold.VenueIDs());
    self.RequestSearch.EventIDs(self.RequestSearchHold.EventIDs());
    self.RequestSearch.CurrentEventsOnly(self.RequestSearchHold.CurrentEventsOnly());
    self.RequestSearch.ActivityIDs(self.RequestSearchHold.ActivityIDs());
    self.RequestSearch.Statuses(self.RequestSearchHold.Statuses());
    self.RequestSearch.AppUserIDs(self.RequestSearchHold.AppUserIDs());
    self.RequestSearch.CompanyIDs(self.RequestSearchHold.CompanyIDs());
    self.RequestSearch.StartReqDate(self.RequestSearchHold.StartReqDate());
    self.RequestSearch.EndReqDate(self.RequestSearchHold.EndReqDate());
    self.RequestSearch.EquipmentTypeIDs(self.RequestSearchHold.EquipmentTypeIDs());
    self.RequestSearch.ManufacturerIDs(self.RequestSearchHold.ManufacturerIDs());
    self.RequestSearch.ModelIDs(self.RequestSearchHold.ModelIDs());
    self.RequestSearch.StartFreq(self.RequestSearchHold.StartFreq());
    self.RequestSearch.EndFreq(self.RequestSearchHold.EndFreq());
    self.RequestSearch.ExcludeStatic(self.RequestSearchHold.ExcludeStatic());
    self.RequestSearch.ShowConflicts(self.RequestSearchHold.ShowConflicts());
    self.RequestSearch.RetrieveCount = listCount;
    self.RequestSearch.RetrieveStartAt = self.RequestSearchHold.RetrieveStartAt;
    self.RequestSearch.PageSize = listCount;
    self.RequestSearch.PageNum = self.RequestSearchHold.PageNum;
    self.RequestSearch.SortBy(temp.SortBy());
    self.RequestSearch.SortDesc(temp.SortDesc());
  };

  self.ClearSearch = function () {
    self.RequestSearch.ClearSearch(self.defaultListCount, self.ShowConflictsDefaultValue);
    self.RequestSearchHold.ClearSearch(self.defaultListCount, self.ShowConflictsDefaultValue);
    $('#EventsToFilter').tokenfield('setTokens', []);
    self.SelectedEventIDs('');
    $('#ActivitiesToFilter').tokenfield('setTokens', []);
    self.SelectedActivityIDs('');

    self.RequestList().Requests([]);
    self.ConflictsStale(true);
    self.LoadList();
    $('.datepicker input[type=text]').val('');
    $('.currentEventsSlide').toggleClass('on', self.RequestSearchHold.CurrentEventsOnly());
  };

  self.reloadAvailVenues = function (areaIDs, initialLoad) {
    if (areaIDs == undefined || areaIDs.length == 0)
      areaIDs = self.Areas().map(function (a) {
        return a.ID;
      });

    $.post(RazorShared.baseUrl + 'LocationMgmt/GetVenuesForAreas', { areaIDs: areaIDs }, function (data) {
      if (data.success) {
        self.AvailVenues(data.AvailVenues.sort(Common.StringSortFunc('Name')));
        if (!initialLoad) {
          var filterd = [];
          self.RequestSearchHold.VenueIDs().forEach(function (id) {
            self.AvailVenues().find(function (v) {
              if (v.ID == id) filterd.push(id);
            });
          });
          self.RequestSearchHold.VenueIDs(filterd);
        } else {
          self.reloadAvailEvents(self.RequestSearchHold.VenueIDs, initialLoad); //Doing this in load function instead of here will run reloading events before venues are loaded.
        }
      } else {
        Common.Dialog('Error finding venues', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Venues', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.reloadAvailEvents = function (venueIDs, initialLoad) {
    if (venueIDs == undefined || venueIDs.length == 0)
      venueIDs = self.AvailVenues().map(function (v) {
        return v.ID;
      });

    $.post(
      RazorShared.baseUrl + 'LocationMgmt/GetEventsForVenues',
      {
        venueIDs: venueIDs,
        currentOnly: self.RequestSearchHold.CurrentEventsOnly()
      },
      function (data) {
        if (data.success && data.AvailEvents) {
          self.AvailEvents(
            (data.AvailEvents || []).map(function (t) {
              return new EventTokenVM(t);
            })
          );
          if (!initialLoad) {
            var filtered = [];
            self
              .SelectedEventIDs()
              .split(', ')
              .forEach(function (id) {
                self.AvailEvents().find(function (e) {
                  if (e.value == id) filtered.push(id);
                });
              });
            self.SelectedEventIDs(filtered.join(', '));
            $('#EventsToFilter').val(self.SelectedEventIDs());
          }

          self.SetupTokenField();
          setTimeout(function () {
            $('#EventsToFilter-tokenfield').blur();
          }, 0);

          //Reload activities
          if (initialLoad) self.reloadAvailActivities(self.RequestSearchHold.EventIDs());
        } else {
          Common.Dialog('Error finding events', null, data.error);
        }
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Events', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.reloadAvailActivities = function (eventIDs, initialLoad) {
    if (eventIDs == undefined || eventIDs.length == 0) {
      $('#ActivitiesToFilter').tokenfield('setTokens', []);
      self.SelectedActivityIDs('');
    }

    $.post(RazorShared.baseUrl + 'LocationMgmt/ReloadActivitiesForEvents', { eventIDs: eventIDs }, function (data) {
      if (data.success && data.AvailActivities) {
        self.Activities(
          (data.AvailActivities || []).map(function (t) {
            return new ActivityTokenVM(t);
          })
        );
        if (!initialLoad) {
          var filtered = [];
          self
            .SelectedActivityIDs()
            .split(', ')
            .forEach(function (id) {
              self.Activities().find(function (e) {
                if (e.value == id) filtered.push(id);
              });
            });
          self.SelectedActivityIDs(filtered.join(', '));
          $('#ActivitiesToFilter').val(self.SelectedActivityIDs());
        }
        self.SetupActivityTokenField();
        setTimeout(function () {
          $('#ActivitiesToFilter-tokenfield').blur();
        }, 0);
      } else {
        Common.Dialog('Error finding activities', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Find Activities', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.SetSort = function (sort) {
    if (self.RequestSearch.SortBy() == sort) {
      self.RequestSearch.SortDesc(!self.RequestSearch.SortDesc());
      self.RequestSearchHold.SortDesc(self.RequestSearch.SortDesc());
    } else {
      self.RequestSearch.SortBy(sort);
      self.RequestSearch.SortDesc(false);
      self.RequestSearchHold.SortBy(sort);
      self.RequestSearchHold.SortDesc(false);
    }
    self.RequestSearch.PageNum = 1;
    self.RequestSearchHold.PageNum = 1;
    self.RequestSearch.GetNextPage = false;
    self.RequestSearchHold.GetNextPage = false;
    self.sortingRequests(true);
    self.ConflictsStale(true);
    self.LoadList();
  };

  self.ListMore = function () {
    if (self.RequestSearch.PageNum * self.RequestSearch.PageSize > self.RequestCount())
      // already loaded everything
      return;

    self.RequestSearchHold.PageNum += 1;
    self.RequestSearchHold.GetNextPage = true;
    self.CopySearchFilters(self.RequestSearchHold.RetrieveCount);
    self.LoadList();
  };

  self.longString = function (str) {
    if (!str) return false;
    var v = str.split(' ');
      for (var x = 0; x < v.length; x++)
         if (v[x].length > 16) return true;
    return false;
  };

  $('#keyTrigger').on('click', () => {
    if ($('.freq.freqRow').hasClass('active')) {
      return;
    }
    setTimeout(() => {
      $('#keyTrigger').attr('data-balloon', null);
         $('.freq.freqRow').attr('style', `top: ${$('#keyTrigger').offset().top + $('#keyTrigger').height() + 2}px`).addClass('active');
    });
  });

  $('body.dashboard').on('click', function (e) {
    $('.freq.freqRow').removeClass('active');
    $('#keyTrigger').attr('data-balloon', 'Frequency Key');
  });


  self.AddRequest = function () {
    DialogMgr.RequestAdd(0, 'Add Request', self.LoadList);
  };

  self.EditRequest = function (requestID) {
    DialogMgr.RequestEdit(requestID, 'Edit Request', self.LoadList);
  };

  self.EditRequestEquipment = function (requestEquipmentID, isInProcess) {
    if (isInProcess && isInProcess == true) {
      Common.DialogConfirmation(
        'Edit Equipment',
        null,
        'Assignment for this equipment has started.  Making changes to Equipment Type, Manufacturer, Model or Frequency Block may cause you to lose your frequency changes, and require you to re-coordinate this equipment.<br><br>Do you want to continue editing this equipment?',
        function () {
          DialogMgr.RequestEquipmentEdit(requestEquipmentID, self.LoadList);
        },
        null
      );
    } else {
    DialogMgr.RequestEquipmentEdit(requestEquipmentID, self.LoadList);
    }
  };

  self.ViewRequest = function (requestID) {
    DialogMgr.RequestView(requestID, 'View Request');
  };

  self.ViewRequestEquipment = function (requestEquipmentID) {
    DialogMgr.RequestEquipmentView(requestEquipmentID);
  };

  self.AddRequestEquipment = function (requestID) {
    DialogMgr.RequestEquipmentAdd(requestID, self.LoadList);
  };

  self.confirmCancelID = 0;

  self.CancelRequest = function (requestID) {
    self.confirmCancelID = requestID;
    Common.DialogConfirmation('Confirm Cancel Request', 'Are you sure you want to cancel Request #' + requestID + '?', null, function () {
      self.CancelConfirmed();
    });
  };

  self.CancelConfirmed = function () {
    if (!self.confirmCancelID || self.confirmCancelID == 0) return;

    $.post(RazorShared.baseUrl + 'Request/CancelRequest/' + self.confirmCancelID, function (data) {
      if (data.success) {
        self.LoadList(self.confirmCancelID);
        }
        else {
        Common.Dialog('Error cancelling Request', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Cancel Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
   
   }

  self.confirmSubmitID = 0;
  self.SubmitRequest = function (requestID) {
    self.confirmSubmitID = requestID;
    Common.DialogConfirmation('Confirm Submit Request', 'Changes to the request will no longer be allowed.', null, function () {
      self.SubmitConfirmed();
    });
  };

  self.SubmitConfirmed = function () {
    if (!self.confirmSubmitID) return;

    $.post(RazorShared.baseUrl + 'Request/SubmitRequest/' + self.confirmSubmitID, function (data) {
      if (data.success) {
        self.LoadList(self.confirmSubmitID);
        RazorShared.dashboardNotification.updateDashboardRequest(self.confirmSubmitID);
        self.confirmSubmitID = 0;
      } else {
        Common.Dialog('Error submitting Request', null, data.error);
        self.confirmSubmitID = 0;
        if (data.emailError && data.emailError == true) self.LoadList();
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      self.confirmSubmitID = 0;
      Common.Dialog('Submit Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.UnsubmitRequest = function (requestID) {
    $.post(RazorShared.baseUrl + 'Request/UnsubmitRequest/' + requestID, function (data) {
      if (data.success) {
        self.LoadList(requestID);
        RazorShared.dashboardNotification.updateDashboardRequest(requestID);
      } else Common.Dialog('Error unsubmitting Request', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Unsubmit Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.CheckinRequest = function (requestID) {
    $.post(RazorShared.baseUrl + 'Request/CheckinRequest/' + requestID, function (data) {
      if (data.success) self.LoadList(requestID);
      else Common.Dialog('Error Checking-In Request', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Checking-In Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.UncheckinRequest = function (requestID) {
    $.post(RazorShared.baseUrl + 'Request/UncheckinRequest/' + requestID, function (data) {
      if (data.success) self.LoadList(requestID);
      else Common.Dialog('Error Unchecking-In Request', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Unchecking-In Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.DuplicateRequest = function (requestID) {
    DialogMgr.RequestDuplicate(requestID, 'Duplicate Request', self.LoadList);
  };

  self.EditRequestNotes = function (requestID) {
    DialogMgr.RequestNoteList(requestID, 'Edit Notes for Request', self.LoadList);
  };

  self.EditRequestEquipmentNotes = function (requestEquipmentID) {
    DialogMgr.RequestEquipmentNoteList(requestEquipmentID, 'Edit Notes for Equipment', self.LoadList);
  };

  self.AddEditRequestOnsiteContacts = function (requestID) {
    DialogMgr.AddEditRequestOnsiteContacts(requestID, 'Add Edit Onsite Contact');
  };

  self.confirmDeleteID = 0;
  self.DeleteRequest = function (requestID) {
    self.confirmDeleteID = requestID;
    Common.DialogConfirmation('Confirm Delete Request', 'Are you sure you want to delete Request #' + requestID + '?', null, function () {
      self.DeleteConfirmed();
    });
  };

  self.DeleteConfirmed = function () {
    if (!self.confirmDeleteID || self.confirmDeleteID == 0) return;

    $.post(RazorShared.baseUrl + 'Request/DeleteRequest/' + self.confirmDeleteID, function (data) {
      if (data.success) {
        self.LoadList();
        RazorShared.dashboardNotification.updateDashboardRequest(self.confirmDeleteID);
        self.confirmDeleteID = 0;
      } else Common.Dialog('Error Deleting Request', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Delete Request', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.AssignFrequencies = function (eventID, requestID, equipmentID) {
    // Check for unapplies static sets and latest spectrum scan before coordination
    var unappliedSets = self.GetUnappliedStaticSets(eventID);
    var locationsWithoutScans = self.GetEventLocationsWithoutLatestScan(eventID);

    $.when(unappliedSets, locationsWithoutScans).done(function () {
      var setResult = unappliedSets.responseJSON;
      var locationResult = locationsWithoutScans.responseJSON;

      if (setResult && setResult.length > 0) {
        self.ConfirmUnassignedSetsAndLocationWithoutScan(eventID, requestID, equipmentID, setResult, locationResult); // This will check for both
      } else if (locationResult && locationResult.length > 0) {
        self.ConfirmLocationsWithoutScans(eventID, requestID, equipmentID, locationResult);
      } else {
        self.AssignFrequenciesConfirmed(eventID, requestID, equipmentID);
      }
    });
  };

  self.ConfirmUnassignedSetsAndLocationWithoutScan = function (eventID, requestID, equipmentID, setsNotApplied, locationsWithoutScan) {
    let notAppliedMsg = '';

    if (setsNotApplied.length == 1) {
      notAppliedMsg = '<span>' + setsNotApplied[0] + ' static frequency set is not applied to this event activity.  ';
    } else {
      let listOfSets = '<ul>';
      for (let i = 0; i < setsNotApplied.length; i++) {
        if (i < 4) {
          listOfSets += '<li>' + setsNotApplied[i] + '</li>';
        } else {
          listOfSets += '<li>...</li>';
          break;
        }
      }
      listOfSets += '</ul>';
      notAppliedMsg = '<span>There are ' + setsNotApplied.length + ' unapplied static frequency sets for this event activity.' + listOfSets;
    }

    Common.DialogConfirmation(
      'Unassigned Static Frequency Sets',
      null,
      notAppliedMsg +
        'It is recommended to go to <a href="/StaticFrequency/StaticFrequencySetsByEventLocation/' +
        eventID +
        '">Static Frequency Set</a> management page to assign them before begining coordination.</span>',
      function () {
        window.location = RazorShared.baseUrl + 'StaticFrequency/StaticFrequencySetsByEventLocation/' + eventID;
      },
      function () {
        if (locationsWithoutScan.length > 0) self.ConfirmLocationsWithoutScans(eventID, requestID, equipmentID, locationsWithoutScan);
        else self.AssignFrequenciesConfirmed(eventID, requestID, equipmentID);
      },
      'Assign Static Set',
      'Continue Coordination'
    );
  };

  self.ConfirmLocationsWithoutScans = function (eventID, requestID, equipmentID, locationsWithoutScan) {
    console.log(locationsWithoutScan);
    let notScanMsg = '';
    if (locationsWithoutScan.length == 1) {
      notScanMsg = locationsWithoutScan[0] + ' does not have latest spectrum scan.  ';
    } else {
      notScanMsg = '<span>Following locations do not have current spectrum scans:';
      let listOfLocatons = '<ul>';
      for (let i = 0; i < locationsWithoutScan.length; i++) {
        listOfLocatons += '<li>' + locationsWithoutScan[i] + '</li>';
      }
      notScanMsg += listOfLocatons + '</ul>';
    }
    Common.DialogConfirmation(
      'Spectrum Scan',
      null,
      notScanMsg +
        'It is recommended to upload latest scans by going to <a href="/LocationMgmt/GetAllLocationsForEventArea/' +
        eventID +
        '">Location Management</a> before begining coordination.',
      function () {
        window.location = RazorShared.baseUrl + 'LocationMgmt/GetAllLocationsForEventArea/' + eventID;
      },
      function () {
        self.AssignFrequenciesConfirmed(eventID, requestID, equipmentID);
      },
      'Upload Scans',
      'Continue Coordination'
    );
  };

  self.GetUnappliedStaticSets = function (eventID) {
    return $.get(RazorShared.baseUrl + 'LocationMgmt/GetStaticSetsNotAppliedForEvent', { id: eventID });
  };

  self.GetEventLocationsWithoutLatestScan = function (eventID) {
    return $.get(RazorShared.baseUrl + 'LocationMgmt/GetEventLocationsWithoutLatestScan', { id: eventID });
  };

  self.AssignFrequenciesConfirmed = function (eventID, requestID, equipmentID) {
    sessionStorage.removeItem('currentIdx');
    var params = {
      eventID: eventID,
      search: ko.toJS(self.RequestSearch),
      selRequestID: requestID,
      selEquipmentID: equipmentID
    };
    JSPost(RazorShared.baseUrl + 'AssignFrequencies/', params);
  };

  self.ShowEventFrequencyReportOptions = function (id) {
    DialogMgr.ShowFrequencyReportOptions(id, 'Frequency Report Options');
  };

  self.ShowEmailMessageDialog = function (requestID) {
    DialogMgr.ShowEmailMessageDialog(requestID, 'Email Message', self.SendRequestCompletedEmail, true);
  };

  self.SendAssignmentClosedEmail = function (requestEquipmentID) {
    $.post(RazorShared.baseUrl + 'AssignFrequencies/SendAssignmentClosedEmail', { equipID: requestEquipmentID }, function (data) {
      if (!data.success) Common.Dialog('Error sending assignment closed email', null, data.error);
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Error sending assignment closed email', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.SendRequestCompletedEmail = function (requestID, message, footer) {
    $.post(
      RazorShared.baseUrl + 'AssignFrequencies/SendRequestCompletedEmail',
      { requestID: requestID, message: message, footer: footer },
      function (data) {
        if (!data.success) Common.Dialog('Error sending request completed email', null, data.error);
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      var emailError =
        XMLHttpRequest.responseJSON && XMLHttpRequest.responseJSON.error.length > 0
          ? XMLHttpRequest.responseJSON.error
          : textStatus + '/' + errorThrown;
      Common.Dialog('Error sending request completed email', emailError, null, XMLHttpRequest.status);
    });
  };

  self.UnassignFrequency = function (equipID, frequency) {
    Common.DialogConfirmation(
      'Confirm Unassign Frequency',
      'Are you sure you want to unassign ' + frequency.toFixed(5).toString() + '?',
      null,
      function () {
        self.UnassignFrequencyConfirmed(equipID, frequency);
      }
    );
  };

  self.UnassignFrequencyConfirmed = function (equipID, frequency) {
    $.post(
      RazorShared.baseUrl + 'AssignFrequencies/UnassignFrequencyDashboard',
      {
        equipID: equipID,
        frequency: frequency,
        updateConflicts: self.GetConflictIndicator()
      },
      function (data) {
        if (data.success) {
          if (!self.GetConflictIndicator()) self.LoadList(data.equip.RequestID);
          else {
            var requestsToUpdateConflicts = [data.equip.RequestID];
            data.updateConflictsForRequests.forEach(function (fid) {
              requestsToUpdateConflicts.push(fid);
            });
            self.LoadList(data.equip.RequestID, self.GetFrequencyConflicts, requestsToUpdateConflicts);
            RazorShared.dashboardNotification.updateDashboardRequest(data.equip.RequestID);
          }
        } else Common.Dialog('Error unassigning frequency', null, data.error);
      }
    ).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      Common.Dialog('Unassign Frequency', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  // Begin Conflict dialog for dashboard
  self.FreqInfo = ko.observable(null);
  self.InfoDetail = true;
  self.IsDialog = true; // Show conflict in dialog instead of hover window

  self.ShowFrequencyConflicts = function (request, equipID, frequency) {
    self.FreqInfo(null);
    var extraWarnings = [];

    let params = {
      procID: request.EventID(),
      equipID: equipID,
      frequency: frequency
    };

    Common.BlockUI();
    $.post(RazorShared.baseUrl + 'AssignFrequencies/EquipmentFrequencyDetailForDashboard', params, function (data) {
      $.unblockUI();
      if (data.success) {
        if (data.extraWarnings) extraWarnings.push(data.extraWarnings);

        var freqConflictVM = new FrequencyConflictVM(data.activities);
        var freqDetail = new FrequencyDataDetailed(data.freqDetail, equipID, freqConflictVM.MakeSelectionMask, extraWarnings, true);

        var requestActivityIDs = request.RequestActivities().map(function (a) {
          return a.ActivityID;
        });
        freqDetail.ApplyFilter(freqConflictVM.SelectionMask(requestActivityIDs));

        self.FreqInfo(freqDetail.Frequency ? freqDetail : null);
        self.OpenConflict();
      } else {
        Common.Dialog('Error Retrieving Frequency Detail', null, data.error);
      }
    }).fail(function (XMLHttpRequest, textStatus, errorThrown) {
      $.unblockUI();
      Common.Dialog('Error Retrieving Frequency Detail', textStatus + '/' + errorThrown, null, XMLHttpRequest.status);
    });
  };

  self.$conflictmodal = null;
  self.OpenConflict = function () {
    if (self.$conflictmodal) {
      self.$conflictmodal.modal('show');
    } else {
      self.$conflictmodal = $('#divConflictDialog');
      self.$conflictmodal.modal({
        backdrop: 'static',
        keyboard: false,
        show: true
      });
      Common.ConfigDialog(self.$conflictmodal);
    }
  };

  self.CloseConflict = function () {
    if (self.$conflictmodal) self.$conflictmodal.modal('hide');
  };
  // End Conflict dialog for dashboard

  self.setupSearchToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayToggled';
    var pinnedCookieName = self.cookieName + '_displayPinned';
    var cookie = Boolean(Cookies.get(toggleCookieName));
    if (Cookies.get(pinnedCookieName)) $fld.click();

    $fld.on('displayToggled', function () {
      if ($(this).hasClass('toggled')) {
        Cookies.set(toggleCookieName, 'toggled');
        pinShut();
    }
      else Cookies.remove(toggleCookieName);
    });
  };

  self.pinToggle = function ($fld) {
    var toggleCookieName = self.cookieName + '_displayPinned';
    var cookie = Boolean(Cookies.get(toggleCookieName));
    if (!cookie) {
      $('#wrapper').addClass('pinned');
      $fld.addClass('active');
      $('#pinContainer').attr('title', 'Unpin Search Options');
    }
    else $('#pinContainer').attr('title','Pin Search Options');
    $('#sectRequestList, .pageHeader').on('click', (e)=>{closeSearch(e)});

    $fld.on('click', function () {
      if ($(this).hasClass('active')) Cookies.set(toggleCookieName, 'true');
      else Cookies.remove(toggleCookieName);
      $('#pinContainer').attr('title', $(this).hasClass('active') ? 'Pin Search Options' : 'Unpin Search Options');
      $(this).toggleClass('active');
      $('#wrapper').toggleClass('pinned');
    });
  };

  var pinShut = function () {
    Cookies.set(self.cookieName + '_displayPinned', 'true');
    $('#sidebarPin').removeClass('active');
    $('#wrapper').removeClass('pinned');
    $('#pinContainer').attr('title', 'Pin Search Options');
  }

  var closeSearch = function (e) {
    if (!$('#menu-toggle').hasClass('toggled') && !$('#sidebarPin').hasClass('active') && !($(e.target).parents('#menu-toggle').length || $(e.target).is('#menu-toggle'))) {
      $('#menu-toggle').trigger('click')
      Cookies.set(self.cookieName + '_displayToggled', 'toggled');
       $('#sidebar-wrapper').scrollTop(0)
      pinShut();
    }
  }

  self.setAreaChangeTrigger = function ($fld) {
    self.RequestSearchHold.AreaIDs.subscribe(function (newVaules) {
      self.reloadAvailVenues(newVaules);
    });
  };

  self.setVenueChangeTrigger = function ($fld) {
    self.RequestSearchHold.VenueIDs.subscribe(function (newValues) {
      self.reloadAvailEvents(newValues);
    });
  };

  self.setCurrentEventsChangeTrigger = function ($fld) {
    self.RequestSearchHold.CurrentEventsOnly.subscribe(function (newValues) {
      self.reloadAvailEvents(self.RequestSearchHold.VenueIDs());
    });
  };

  self.setEventChangeTrigger = function ($fld) {
    self.RequestSearchHold.EventIDs.subscribe(function (newValues) {
      self.reloadAvailActivities(self.RequestSearchHold.EventIDs());
    });
  };

  self.SetupTokenField = function () {
    self.$eventsTokenField = $('#EventsToFilter');

    // We will have just reloaded the dialog div, so we need to rehook tokenfield handlers here
    self.$eventsTokenField.tokenfield({
      autocomplete: {
        source: [],
        delay: 100
      },
      showAutocompleteOnFocus: true
    });

    self.$eventsTokenField.on('tokenfield:createtoken', function (event) {
      self.onCreateToken(event);
      setTimeout(function () {
        $('#EventsToFilter-tokenfield').blur();
        $('#EventsToFilter-tokenfield').focus();
        //$('#EventsToFilter-tokenfield').click();
      }, 0);
    });

    self.$eventsTokenField.on('tokenfield:edittoken', function (event) {
      return false;
    });

    self.$eventsTokenField.on('tokenfield:createdtoken', function (event) {
      self.setAvailableTokens();
    });

    self.$eventsTokenField.on('tokenfield:removedtoken', function (event) {
      self.setAvailableTokens();
    });

    // DJM 07.18.23 for EFC-1493
    $( "#ui-id-1" ).on( "menufocus", function( event, ui ) {
      $('#ui-id-1 li.ui-state-focus').removeClass('ui-state-focus');
      $(ui.item[0]).addClass('ui-state-focus');
    });

    // refresh...
    self.tokenChoices = ko.toJS(self.AvailEvents());

    self.$eventsTokenField.tokenfield('setTokens', self.$eventsTokenField.val());
    self.setAvailableTokens();
  };

  self.onCreateToken = function (event) {
    // make sure token matches an existing set but not duplicate of one already selected

    var isManual = event.attrs.value === event.attrs.label; // manual entry won't have our value

    var setToken = self.tokenChoices.find(function (token) {
      return token.value == event.attrs.value || (isManual && token.label == event.attrs.value);
    });
    if (setToken && isManual) event.attrs.value = setToken.value;

    var duplicate = self.$eventsTokenField.tokenfield('getTokens').find(function (token) {
      return token.value == event.attrs.value;
    });

    if (setToken && !duplicate) {
      event.attrs.label = setToken.label; // make sure to display correct label on init
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  };

  self.setAvailableTokens = function () {
    var curTokens = self.$eventsTokenField.tokenfield('getTokens');
    var availTokens = $.map(self.tokenChoices, function (token) {
      if (
        curTokens.find(function (t2) {
          return t2.value == token.value;
        })
      )
        return null; // eliminates it from mapped array
      else return token; // retains it in mapped array
    });
    self.$eventsTokenField.data('bs.tokenfield').$input.autocomplete({ source: availTokens });
  };

  self.SetupActivityTokenField = function () {
    self.$activitiesTokenField = $('#ActivitiesToFilter');

    // We will have just reloaded the dialog div, so we need to rehook tokenfield handlers here
    self.$activitiesTokenField.tokenfield({
      autocomplete: {
        source: [],
        delay: 100
      },
      showAutocompleteOnFocus: true
    });

    self.$activitiesTokenField.on('tokenfield:createtoken', function (activity) {
      self.onCreateActivityToken(activity);
      setTimeout(function () {
        $('#ActivitiesToFilter-tokenfield').blur();
        $('#ActivitiesToFilter-tokenfield').focus();
        //$('#ActivitiesToFilter-tokenfield').click();
      }, 0);
    });

    self.$activitiesTokenField.on('tokenfield:edittoken', function (activity) {
      return false;
    });

    self.$activitiesTokenField.on('tokenfield:createdtoken', function (activity) {
      self.setAvailableActivityTokens();
    });

    self.$activitiesTokenField.on('tokenfield:removedtoken', function (activity) {
      self.setAvailableActivityTokens();
    });

    // refresh...
    self.tokenActivityChoices = ko.toJS(self.Activities);

    self.$activitiesTokenField.tokenfield('setTokens', self.$activitiesTokenField.val());
    self.setAvailableActivityTokens();
  };

  self.onCreateActivityToken = function (activity) {
    // make sure token matches an existing set but not duplicate of one already selected

    var isManual = activity.attrs.value === activity.attrs.label; // manual entry won't have our value

    var setToken = self.tokenActivityChoices.find(function (token) {
      return token.value == activity.attrs.value || (isManual && token.label == activity.attrs.value);
    });
    if (setToken && isManual) activity.attrs.value = setToken.value;

    var duplicate = self.$activitiesTokenField.tokenfield('getTokens').find(function (token) {
      return token.value == activity.attrs.value;
    });

    if (setToken && !duplicate) {
      activity.attrs.label = setToken.label; // make sure to display correct label on init
      return true;
    } else {
      activity.preventDefault();
      return false;
    }
  };

  self.setAvailableActivityTokens = function () {
    var curTokens = self.$activitiesTokenField.tokenfield('getTokens');
    var availTokens = $.map(self.tokenActivityChoices, function (token) {
      if (
        curTokens.find(function (t2) {
          return t2.value == token.value;
        })
      )
        return null; // eliminates it from mapped array
      else return token; // retains it in mapped array
    });
    self.$activitiesTokenField.data('bs.tokenfield').$input.autocomplete({ source: availTokens });
  };

  // Start Search Token/Breadcrumb Descriptions
  self.selectedAreaDescription = ko.pureComputed(function () {
    let selectedAreaNames = '';
    self.RequestSearch.AreaIDs().forEach(function (id) {
      var item = self.Areas().find((x) => x.ID == id);
      if (item != null) selectedAreaNames += (selectedAreaNames.length > 0 ? ', ' : '') + item.Name;
    });
    return selectedAreaNames;
  });

  self.selectedVenueDescription = ko.pureComputed(function () {
    let selectedVenueNames = '';
    self.RequestSearch.VenueIDs().forEach(function (id) {
      var item = self.AvailVenues().find((x) => x.ID == id);
      if (item != null) selectedVenueNames += (selectedVenueNames.length > 0 ? ', ' : '') + item.Name;
    });
    return selectedVenueNames;
  });

  self.selectedEventDescription = ko.pureComputed(function () {
    let selectedEventNames = '';
    self.RequestSearch.EventIDs().forEach(function (id) {
      var item = self.AvailEvents().find((x) => x.value == id);
      if (item != null) selectedEventNames += (selectedEventNames.length > 0 ? ', ' : '') + item.label;
    });
    return selectedEventNames;
  });

  self.selectedActivityDescription = ko.pureComputed(function () {
    let selectedActivityNames = '';
    self.RequestSearch.ActivityIDs().forEach(function (id) {
      var item = self.Activities().find((x) => x.value == id);
      if (item != null) selectedActivityNames += (selectedActivityNames.length > 0 ? ', ' : '') + item.label;
    });
    return selectedActivityNames;
  });

  self.selectedCompanyDescription = ko.pureComputed(function () {
    let selectedCompanyNames = '';
    self.RequestSearch.CompanyIDs().forEach(function (id) {
      var item = self.Companies().find((x) => x.ID == id);
      if (item != null) selectedCompanyNames += (selectedCompanyNames.length > 0 ? ', ' : '') + item.Name;
    });
    return selectedCompanyNames;
  });

  self.selectedStatusDescription = ko.pureComputed(function () {
    let selectedStatusNames = '';
    self.RequestSearch.Statuses().forEach(function (id) {
      var item = self.StatusesAll().find((x) => x.Key == id);
      if (item != null) selectedStatusNames += (selectedStatusNames.length > 0 ? ', ' : '') + item.Value;
    });
    return selectedStatusNames;
  });

  self.selectedEquipmentTypeDescription = ko.pureComputed(function () {
    let selectedEquipmentTypeNames = '';
    self.RequestSearch.EquipmentTypeIDs().forEach(function (id) {
      var item = self.EquipmentTypes().find((x) => x.ID == id);
      if (item != null) selectedEquipmentTypeNames += (selectedEquipmentTypeNames.length > 0 ? ', ' : '') + item.Name;
    });
    return selectedEquipmentTypeNames;
  });

  self.selectedManufacturerDescription = ko.pureComputed(function () {
    let selectedManufacturerNames = '';
    self.RequestSearch.ManufacturerIDs().forEach(function (id) {
      var item = self.Manufacturers().find((x) => x.ID == id);
      if (item != null) selectedManufacturerNames += (selectedManufacturerNames.length > 0 ? ', ' : '') + item.Name;
    });
    return selectedManufacturerNames;
  });

  self.selectedModelDescription = ko.pureComputed(function () {
    let selectedModelNames = '';
    self.RequestSearch.ModelIDs().forEach(function (id) {
      var item = self.Models().find((x) => x.ID == id);
      if (item != null) selectedModelNames += (selectedModelNames.length > 0 ? ', ' : '') + item.Name;
    });
    return selectedModelNames;
  });

  self.selectedAppUserDescription = ko.pureComputed(function () {
    let selectedAppUserNames = '';
    self.RequestSearch.AppUserIDs().forEach(function (id) {
      var item = self.AppUsers().find((x) => x.ID == id);
      if (item != null) selectedAppUserNames += (selectedAppUserNames.length > 0 ? ', ' : '') + item.Name;
    });
    return selectedAppUserNames;
  });
  // End Search Token/Breadcrumb Descriptions

  self.formatToDateOnly = function (d) {
    return d ? moment(d).format('l') : '';
  };

  self.clickedCopy = function (item) {
    if (!$(item).hasClass('btnCopyEmail')) item = $(item).parents('.btnCopyEmail')[0];
    $(item).addClass('copied');
    setTimeout(function () {
      $(item).removeClass('copied');
    }, 2000);
  };

  // Begin SignalR
  self.UpdateRequest = function (requestID) {
    console.log('Request ' + requestID + ' updated, update dashboard');
    if (
      self
        .RequestList()
        .Requests()
        .findIndex(function (r) {
          return r.RequestID == requestID;
        }) >= 0
    ) {
      self.LoadList(requestID);
    }
  };

  //End SignalR
};

var RequestSearch = function (jsonData, defaultCount) {
  var self = this;

  self.RequestID = ko.observable(jsonData.RequestID || null);
  self.AreaIDs = ko.observableArray(jsonData.AreaIDs || []);
  self.VenueIDs = ko.observableArray(jsonData.VenueIDs || []);
  self.EventIDs = ko.observableArray(jsonData.EventIDs || []);
  self.CurrentEventsOnly = ko.observable(jsonData.CurrentEventsOnly != undefined ? jsonData.CurrentEventsOnly : true);
  self.ActivityIDs = ko.observableArray(jsonData.ActivityIDs || []);
  self.Statuses = ko.observableArray(jsonData.Statuses || []);
  self.AppUserIDs = ko.observableArray(jsonData.AppUserIDs || []);
  self.CompanyIDs = ko.observableArray(jsonData.CompanyIDs || []);
  self.StartReqDate = ko.observable(jsonData.StartReqDate || null);
  self.EndReqDate = ko.observable(jsonData.EndReqDate || null);
  self.EquipmentTypeIDs = ko.observableArray(jsonData.EquipmentTypeIDs || []);
  self.ManufacturerIDs = ko.observableArray(jsonData.ManufacturerIDs || []);
  self.ModelIDs = ko.observableArray(jsonData.ModelIDs || []);
  self.StartFreq = ko.observable(jsonData.StartFreq || null);
  self.EndFreq = ko.observable(jsonData.EndFreq || null);
  self.ExcludeStatic = ko.observable(jsonData.ExcludeStatic != undefined ? jsonData.ExcludeStatic : false);
  self.ShowConflicts = ko.observable(jsonData.ShowConflicts != undefined ? jsonData.ShowConflicts : false);

  self.SortBy = ko.observable(jsonData.SortBy || 0); //Default is RequestID
  self.SortDesc = ko.observable(jsonData.SortDesc || (jsonData.SortBy ? false : true)); //When no Sort By is specified we want default sort to be RequestID Descending, otherwise Ascending is default order)
  self.RetrieveCount = defaultCount;
  self.RetrieveStartAt = jsonData.RetrieveStartAt || null;
  self.PageSize = defaultCount;
  self.PageNum = jsonData.PageNum || 1;
  self.TrackInfo = '';

  self.GetNextPage = jsonData.GetNextPage || false; //Append or Update

  var minSqlDate = moment('1753-01-01');
  var maxSqlDate = moment('9999-12-30'); //30 because stored procedure adds a day

  self.StartReqDateInValid = ko.pureComputed(function () {
    return moment(self.StartReqDate()).isBefore(minSqlDate) || moment(self.StartReqDate()).isAfter(maxSqlDate);
  });
  self.EndReqDateInValid = ko.pureComputed(function () {
    return moment(self.EndReqDate()).isBefore(minSqlDate) || moment(self.EndReqDate()).isAfter(maxSqlDate);
  });

  self.ClearSearch = function (defaultCount, showConflictsDefaultValue) {
    if (defaultCount == undefined) return;
    self.RequestID(null);
    self.AreaIDs([]);
    self.VenueIDs([]);
    self.EventIDs([]);
    self.CurrentEventsOnly(true);
    self.ActivityIDs([]);
    self.Statuses([]);
    self.AppUserIDs([]);
    self.CompanyIDs([]);
    self.StartReqDate(null);
    self.EndReqDate(null);
    self.EquipmentTypeIDs([]);
    self.ManufacturerIDs([]);
    self.ModelIDs([]);
    self.StartFreq(null);
    self.EndFreq(null);
    self.ExcludeStatic(false);
    self.ShowConflicts(showConflictsDefaultValue);
    self.RetrieveCount = defaultCount;
    self.PageSize = defaultCount;
    self.PageNum = 1;
    self.GetNextPage = false;
  };

  self.nonDefaultFilterSelected = ko.pureComputed(function () {
    return (
      self.RequestID() > 0 ||
      self.AreaIDs().length > 0 ||
      self.VenueIDs().length > 0 ||
      self.EventIDs().length > 0 ||
      !self.CurrentEventsOnly() ||
      self.ActivityIDs().length > 0 ||
      self.Statuses().length > 0 ||
      self.AppUserIDs().length > 0 ||
      self.CompanyIDs().length > 0 ||
      self.StartReqDate() != null ||
      self.EndReqDate() != null ||
      self.EquipmentTypeIDs().length > 0 ||
      self.ManufacturerIDs().length > 0 ||
      self.ModelIDs().length > 0 ||
      self.StartFreq() != null ||
      self.EndFreq() != null ||
      self.ExcludeStatic()
    );
  });
};

var RequestListVM = function (jsonData) {
  var self = this;

  self.AssignFrequenciesAll = jsonData.AssignFrequenciesAll;
  self.ShowReports = jsonData.ShowReports;
  self.CanSendEmails = jsonData.CanSendEmails;
  self.AssignFrequenciesAreas = jsonData.AssignFrequenciesAreas;
  self.AllowEditingSubmitedOrInProcessRequest = jsonData.AllowEditingSubmitedOrInProcessRequest;
  self.CanSeeConflicts = jsonData.CanSeeConflicts;

  self.Requests = ko.observableArray([]);
  if (jsonData)
    self.Requests(
      (jsonData.Requests || []).map(function (r) {
        return new RequestVM(r, true);
      })
    );

  self.AssignFrequenciesAreasContains = function (id) {
    if (!self.AssignFrequenciesAreas) return false;

    return self.AssignFrequenciesAreas.some(function (areaid) {
      return areaid == id;
    });
  };

  self.ShowAssignFrequencyByArea = function (id) {
    // Still need to check request status.  Not including here since it can change.
    return self.AssignFrequenciesAll || (self.AssignFrequenciesAreas.length > 0 && self.AssignFrequenciesAreasContains(id));
  };

  self.UpdateRequests = function (requests) {
    self.Requests(
      (requests || []).map(function (r) {
        return new RequestVM(r, true);
      })
    );
  };

  self.AppendRequests = function (requests) {
    requests.forEach(function (request) {
      self.Requests.push(new RequestVM(request, true));
    });
  };

  self.UpdateRequest = function (request, index) {
    var idx = self.Requests().findIndex(function (r) {
      return r.RequestID == request.RequestID;
    });
    if (index == idx) self.Requests.splice(index, 1, new RequestVM(request, true));
  };

  self.RemoveRequest = function (index) {
    self.Requests.splice(index, 1);
  };
};

var FrequencyConflictVM = function (data) {
  var self = this;

  var currBitFlag = 1;
  self.AllActivities = [];

  data.forEach(function (s) {
    s.BitFlag = currBitFlag;
    self.AllActivities.push(s);
    currBitFlag <<= 1;
  });

  self.MakeSelectionMask = function (conflictIDs) {
    var bitmask = 0;
    if (conflictIDs) {
      conflictIDs.forEach(function (id) {
        var act = self.AllActivities.find(function (a) {
          return a.ActivityID == id;
        });
        if (act) bitmask += act.BitFlag;
      });
    }
    return bitmask;
  };

  self.SelectionMask = function (requestActivities) {
    var mask = 0;

    var activities = self.AllActivities.filter(function (a) {
      return requestActivities.indexOf(a.ActivityID) !== -1;
    });
    activities.forEach(function (a) {
      mask += a.BitFlag;
    });

    return mask;
  };
};

var EventTokenVM = function (data) {
  var self = this;

  self.value = data.ID;
  self.label = data.Name;
};

var ActivityTokenVM = function (data) {
  var self = this;

  self.value = data.ID;
  self.label = data.Name;
};

export default DashboardVM;
